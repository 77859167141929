<template>
	<div>
		<div class="row">
			<div class="col-12 col-md-6"><h1>Users</h1></div>
			<div class="col-12 col-md-6 text-right">
				<router-link to="/user/0" class="btn btn-sm btn-success mt-3"><i class="fa-regular fa-plus-circle"></i> Add User</router-link>
			</div>
		</div>

		<vue-good-table
			mode="remote"
			styleClass="vgt-table striped bordered condensed"
			:theme="vgtTheme"
			v-on:page-change="onPageChange"
			v-on:sort-change="onSortChange"
			v-on:column-filter="onColumnFilter"
			v-on:per-page-change="onPerPageChange"
			:totalRows="records"
			v-model:isLoading.sync="loading"
			:pagination-options="{
				enabled: true,
				perPage: 25,
				perPageDropdown: [10, 25, 50, 100],
				setCurrentPage: initial_page
			}"
			:sortOptions="{
				enabled: true,
				multipleColumns: true,
				initialSortBy: initial_sort
			}"
			:rows="rows"
			:columns="columns"
			:key="vgt_reload_count">

			<template #table-row="props">
				<div v-if="props.column.field == 'fname' || props.column.field == 'lname'">
					<router-link :to="'/user/' + props.row.user_id">{{props.formattedRow[props.column.field]}}</router-link>
				</div>
				<div v-else-if="props.column.field == 'email'">
					<a :href="'mailto:' + props.row.email" :id="'email_' + props.row.user_id">{{props.formattedRow[props.column.field]}}</a>
				</div>
				<div v-else-if="props.column.type == 'boolean'" class="text-center">
					<i class="fa-regular fa-check-circle text-success" v-if="props.formattedRow[props.column.field] == 'Yes'"></i>
					<i class="fa-regular fa-times-circle text-light" v-if="props.formattedRow[props.column.field] != 'Yes'"></i>
				</div>
				<div v-else>
					{{props.formattedRow[props.column.field]}}
				</div>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import 'vue-good-table-next/dist/vue-good-table-next.css'
	import { VueGoodTable } from 'vue-good-table-next';

	export default
	{
		name: 'Users',
		components: {
			VueGoodTable
		},
		data()
		{
			return {
				required_permissions: ["IDDQD", "ADMIN"],
				loading: false,
				columns: [
					{
						label: "First Name",
						field: "fname",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					},
					{
						label: "Last Name",
						field: "lname",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					},
					{
						label: "Username",
						field: "username",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					},
					{
						label: "Email",
						field: "email",
						sortable: true,
						filterOptions:
						{
							enabled: true
						}
					},
					{
						label: "Active",
						field: "active",
						type: "boolean",
						sortable: true,
						width: "100px",
						filterOptions:
						{
							enabled: true,
							filterDropdownItems: [{value: 1, text: "Yes"}, {value: 0, text: "No"}]
						},
						formatFn(value)
						{
							return (value ? "Yes" : "No");
						}
					}
				],
				rows: [],
				records: 0,
				initial_sort: [
					{field: 'active', type: 'desc'},
					{field: 'fname', type: 'asc'},
					{field: 'lname', type: 'asc'}
				],
				initial_page: 1
			}
		},
		computed:
		{
			...mapGetters([
				"user"
			])
		},
		methods:
		{
			load()
			{
				// Avoid some VGT events from loading more than once.
				if(this.loading)
				{
					return;
				}

				this.loading = true;
				this.$store.commit("startLoading");

				this.CORS("POST", "/users/filtered", JSON.stringify(this.search_params),
				(response) =>
				{
					this.records = response.records;
					this.rows = response.rows;
					this.loading = false;
					this.$store.commit("stopLoading");
				},
				(response) =>
				{
					this.showError("Error Loading Users", response.responseText, true, null);
					this.loading = false;
					this.$store.commit("stopLoading");
				})
			}
		}
	}
</script>
